import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getCurrentLocation, getDistanceFromLatLonInKm } from "../../../components/src/helpers";
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import moment from "moment";

export type RequestBody = {
  start_date: string;
  end_date?: string;
  service_type: string | undefined;
  country: string | undefined;
  city: string | undefined;
  your_pet: string | undefined;
  min_price: number | string;
  max_price: number | string;
  transportation: boolean;
  sub_service_type?: string; 
  sort_order: SortOptions;
  commodities?: string;
}

export type SearchItem = {
  id: number,
  establishment_name: string,
  address: string,
  country: string,
  city: string,
  zipcode: string,
  longitude: number,
  latitude: number,
  email: string,
  phone_number: number,
  facebook_url: string,
  instagram_url: string,
  linkedin_url: string,
  tiktok_url: string,
  activated: boolean,
  created_at: string,
  updated_at: string,
  vandor_name: string,
  service_price: number,
  establishment_photo: string | null,
  galleries_urls: string[],
  service_details: {
    "id": number | string,
    "service_type": string
  }
};

type Region = {
  latitude: number;
  longitude: number;
};

export type Filters = {
  price: {
    max: string;
    min: string;
  };
  transport: string[];
  commodities: string[];
  hotelType: string[];
  grooming: string[];
  dayCare: string[];
  training: string[];
};

type Cords = { latitude: number, longitude: number };

interface APIModal {
  body?: object;
  formData?: object;
  token?: string;
  contentType?: string;
  method: string;
  endPoint: string;
}

interface CategoryCount {
  [key: string]: number;
}

interface PetCategory {
  label: string;
  value: string;
}

export type SortOptions = "closest" | "desc" | "asc";

export interface IPet {
  pet_category: string;
  id: number;
  name?: string;
  gender: string;
  breed: string;
  birthdate: string;
  weight: string;
  size: string;
  account_id: number;
  fur: string;
  species: string;
  created_at?: string;
  updated_at?: string;
  pet_photo: null | string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onSearch:(item:SearchItem)=>void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  petsList: PetCategory[];
  searchItems: SearchItem[];
  region: Region;
  sortOption: SortOptions;
  searchTab: number;
  filters: Filters;
  currrentCords: null | Cords;
  isAppLoading: boolean;
  searchData?: SearchData;
  isLoading: boolean;
  isOpenMap: boolean; 
  open:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetSearchResults: string = "";
  apiPetListCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      petsList: [],
      searchItems: [],
      sortOption: "asc",
      isAppLoading: true,
      searchTab: Number(localStorage.getItem('searchTab')) || 0,
      isLoading: false,
      isOpenMap: false,
      filters: {
        price: {
          max: "",
          min: "",
        },
        transport: [],
        commodities: [],
        hotelType: [],
        grooming: [],
        dayCare: [],
        training: ["individual training"],
      },
      region: {
        latitude: 38.719567,
        longitude: -9.139996,
      },
      currrentCords: null,
      open:false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getCurrentLocationAndSetState();
    this.doEmailLogin();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.makeSearch(data);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.handleSession(responseJson);
      if(apiRequestCallId === this.apiPetListCallId) {
        if(responseJson && responseJson.data) {
          const petsList = responseJson.data.map(
            (pet: { attributes: IPet }) => pet.attributes
          );
          this.setState({ petsList: this.getUniquePetCategories(petsList) });
        }
      }
      if(apiRequestCallId === this.apiGetSearchResults) {
        if(responseJson && responseJson.data) {
          const results = responseJson.data.map(
            (place: { attributes: SearchItem }) => place.attributes
          );
          this.setSearchResults(results);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  objectToQueryString(obj: Record<any, any>) {
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined) 
      .map(([key, value]) => `${key}=${value === null ? '' : value}`)
      .join('&');
  }

  getUniquePetCategories(pets: IPet[]): PetCategory[] {
    const categoryCount: CategoryCount = pets.reduce((acc: CategoryCount, pet: IPet) => {
      const category = pet.pet_category;
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});
  
    const uniqueCategories: PetCategory[] = Object.entries(categoryCount).map(([category, count]) => {
      let value: string;
      const label = category.charAt(0).toUpperCase() + category.slice(1);
      if (category === 'cat') {
        value = 'cats'
      } else if (category === 'rabbit/rodent') {
        value = 'rabbit';
      } else {
        value = category;
      }
      return { label, value };
    });
  
    return uniqueCategories;
  }

  doEmailLogin = () => {
    const token = localStorage.getItem("login_token");
    if(token) {
      this.setState({ token, isAppLoading: false });
    }
  };

  goToLoginPage = () => {
    this.navigateToPage("EmailAccountLoginBlock");
  };

  navigateToPage = (targetPage: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleSession = (responseJson: any) => {
    if(responseJson && responseJson.errors) {
      if(Array.isArray(responseJson.errors)) {
        if(responseJson.errors[0].token) {
          localStorage.removeItem("login_token");
          localStorage.removeItem("login_user_name");
          localStorage.removeItem("login_user_id");
          localStorage.removeItem("login_refresh_token");
          localStorage.removeItem("isUserLogin");
          localStorage.removeItem("login_user_role");
          localStorage.removeItem("login_email");
          localStorage.removeItem("login_user_profile");
          this.forceUpdate();
        }
      }
    }
  }

  apiCall = async (data: APIModal) => {
    const { contentType, method, endPoint, body, token, formData } = data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    formData &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getUserPets = async () => {
    if(this.state.token) {
      this.apiPetListCallId = await this.apiCall({
        contentType: configJSON.httpContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.petListApiEndPoint,
        token: this.state.token,
      });
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.sortOption !== this.state.sortOption) {
      if(this.state.sortOption === 'closest') {
        this.sortSearchResults();
      } else if(this.state.searchData) {
        this.makeSearch(this.state.searchData);
      }
    }
    if(prevState.isAppLoading !== this.state.isAppLoading) {
      this.getUserPets();
    }
  }

  getClosestSortList = (list: SearchItem[], currrentCords: Cords) => {
    return list.sort((place1, place2) => {
      const distance1 = getDistanceFromLatLonInKm(currrentCords.latitude, currrentCords.longitude, place1.latitude, place1.longitude);
      const distance2 = getDistanceFromLatLonInKm(currrentCords.latitude, currrentCords.longitude, place2.latitude, place2.longitude);
      return distance1 - distance2; 
    })
  }

  sortSearchResults = () => {
    const { sortOption, currrentCords, searchItems} = this.state;
    if(searchItems && searchItems.length) {
      let newList: SearchItem[] = [];
      if(sortOption === 'closest' && currrentCords) {
        newList = this.getClosestSortList(searchItems, currrentCords);
        this.setState({ searchItems: newList });
      } 
    }
  }

  getCurrentLocationAndSetState() {
    getCurrentLocation()
      .then((coords: Cords) => {
        this.setState({ currrentCords: coords, sortOption: "closest" });
        this.sortSearchResults();
      })
      .catch(() => {
        return;
      });
  }

  setSearchResults = (results: SearchItem[]) => {
    this.setState({ isLoading: false, searchItems: results});
    if(results.length > 0) {
      this.setState({ region: {
        latitude: results[0].latitude,
        longitude: results[0].longitude,
      }})
    }
    this.sortSearchResults();
  };

  handleChangeSortOrder = (value: SortOptions) => {
    this.setState({ sortOption: value });
  }
  handleSeeOffer = (searchItem: SearchItem) => {
    const serviceId = Number(searchItem.service_details.id)
    const NavigationData = {
      bookingService : searchItem,
      searchFilterData : this.state.searchData
    }
    
    if(localStorage.getItem("isUserLogin")) {
      localStorage.setItem("service_id", serviceId.toString());
      const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
      NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'Booking'); // ROUTE
      NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), serviceId); // PARAM
      
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), NavigationData); // DATA
      
      NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        landingPageTabValue: this.state.searchTab,
      });
      NavigateMsg.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
      this.send(NavigateMsg);
    } else {
      this.goToLoginPage();
    }
    
  }
 
  getMarkersList = () => {
    if (this.state.searchItems) {
      return this.state.searchItems.map((item) => ({
        id: 0,
        establishment_name: item.establishment_name,
        address: item.address,
        country:'',
        city: '',
        zipcode:'',
        longitude:item.longitude,
        latitude:item.latitude,
        email: '',
        phone_number: 0,
        facebook_url: '',
        instagram_url: '',
        linkedin_url: '',
        tiktok_url: '',
        activated: false,
        created_at: '',
        updated_at: '',
        vandor_name:'',
        service_price: item.service_price,
        establishment_photo: item.establishment_photo,
        galleries_urls:[],
        service_details: {
          id:item.service_details.id,
          "service_type": ''
        }
      }));
    }
    return [];
  };
  getSortOrder = () => {
    if(this.state.sortOption === 'closest') {
      return 'asc'
    } else {
      return this.state.sortOption
    }
  }

  getCommodities = (serviceType: string | undefined) => {
    if(!serviceType) {
      return ''
    }

    if(serviceType === 'Hotels') {
      const results = [...this.state.filters.commodities];
      return results.join(', ')
    }

    if(serviceType === 'Day Care') {
      const results = [...this.state.filters.dayCare];
      return results.join(', ')
    }
  }

  getServiceType = () => {
    const mapServiceTypes: Record<number, string> = {
      0: 'Hotels',
      1: 'Dog Walking',
      2: 'Grooming',
      3: 'Pet Sitting', 
      4: 'Day Care',
      5: 'Training'
    }

    return mapServiceTypes[this.state.searchTab];
  }

  getSearchData = (searchData: SearchData) => {
    console.log("DATA from search" ,searchData)
  }

  applyFilters = (data: SearchData) => {
    if(this.state.searchData) {
      this.makeSearch({...this.state.searchData, service_type: this.getServiceType()})
    }
  }

  makeSearch = async (data: SearchData) => {
    this.setState({ isLoading: true });
    const withEndDate = ['Hotels', 'Dog Walking'];
    const commodities = this.getCommodities(data.service_type)
    this.setState({ searchData: data });
    const params: RequestBody = {
      start_date: moment(data.start_date).format('DD-MM-YYYY'),
      service_type: data.service_type,
      country: data.country,
      city: data.city,
      your_pet: data.your_pet,
      min_price: this.state.filters.price.min || 0,
      max_price: this.state.filters.price.max ? this.state.filters.price.max : 50000,
      transportation: !!this.state.filters.transport.length,
      sort_order: this.getSortOrder(),
      end_date: moment(data.start_date).format('DD-MM-YYYY')
    }
    if(withEndDate.includes(data.service_type || '')) {
      params.end_date = moment(data.end_date).format('DD-MM-YYYY')
    }
    if(data.service_type === 'Hotels' && this.state.filters.hotelType.length) {
      params.sub_service_type = [...this.state.filters.hotelType].join(", ")
    }
    if(data.service_type === 'Training') {
      params.sub_service_type = this.state.filters.training.join(', ');
    }
    if(commodities) {
      params.commodities = commodities
    }
    const queryString = this.objectToQueryString(params);
    this.apiGetSearchResults = await this.apiCall({
      contentType: configJSON.httpContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.searchApiEndPoint}?${queryString}`,
    });
    return data;
  };

  changePriceFilters = (field: string, value?: string) => {
    const newPrice = { ...this.state.filters.price, [field]: value };

    return newPrice;
  }

  changeFilterValue = (type: keyof Filters, field: string, value?: string) => {
    if (type === "price") {
      const newPrice = this.changePriceFilters(field, value)
      this.setState({
        filters: {
          ...this.state.filters,
          price: newPrice,
        },
      });
    } else {
      this.setState((prevState) => {
        return {
          filters: {
            ...prevState.filters,
            [type]: prevState.filters[type].includes(field)
              ? prevState.filters[type].filter((i) => i !== field)
              : [...prevState.filters[type], field],
          },
        };
      });
    }
  };
  // Customizable Area End
}
