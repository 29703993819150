import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  handelCloseClick: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewImageIndex: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ServiceGalleryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      viewImageIndex: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleImageClick = (url:string) => {
    this.setState({viewImageIndex:url})
  }
  handleBackImageClick = () => {
    this.setState({viewImageIndex:""})
  }
  // Customizable Area End
}