import React, { createContext, useState, ReactNode, useContext } from 'react';

export interface SearchData {
  country?: string;
  city?: string;
  start_date?: Date;
  end_date?: Date;
  your_pet?: string;
  everyWeek?: number;
  everyDay?: string;
  endAfter?: Date;
  service_type?: string;
}
export interface SearchHeaderContextType {
    startDate: Date | undefined;
    setStartDate: (date: Date | undefined) => void;
    endDate: Date | undefined;
    setEndDate: (date: Date | undefined) => void;
    selectedDay: string;
    setSelectedDay: (day: string) => void;
    isRepeat: boolean;
    setIsRepeat: (isRepeat: boolean) => void;
    isFilterOpen: boolean;
    setIsFilterOpen: (isFilterOpen: boolean) => void;
    petType: string;
    setPetType: (petType: string) => void;
    endAfterDate: Date | undefined;
    setEndAfterDate: (date: Date | undefined) => void;
    countries: { label: string; value: string | number | undefined; }[];
    setCountries: (countries: { label: string; value: string | number | undefined; }[]) => void;
    cities: { label: string; value: string | number | undefined; }[];
    setCities: (cities: { label: string; value: string | number | undefined; }[]) => void;
    selectedCountry: string;
    setSelectedCountry: (country: string) => void;
    selectedCity: string;
    setSelectedCity: (city: string) => void;
    errors: string[];
    setErrors: (errors: string[]) => void;
    removeError: (key: string) => void;
    handleResetClick: () => void;
    searchData: SearchData | undefined;
    setSearchData: (data: SearchData) => void;
  }



  const SearchHeaderContext = createContext<SearchHeaderContextType | undefined>(undefined);

  export const SearchHeaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [selectedDay, setSelectedDay] = useState("");
    const [isRepeat, setIsRepeat] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [petType, setPetType] = useState("");
    const [endAfterDate, setEndAfterDate] = useState<Date>();
    const [countries, setCountries] = useState<{ label: string; value: string | number | undefined; }[]>([]);
    const [cities, setCities] = useState<{ label: string; value: string | number | undefined; }[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>("Portugal");
    const [selectedCity, setSelectedCity] = useState<string>("");
    const [errors, setErrors] = useState<string[]>([]);
    const [searchData, setSearchData] = useState<SearchData | undefined>(undefined);

    const removeError = (key: string) => {
        setErrors(prevErrors => prevErrors.filter(error => error !== key));
    };
    
    const handleResetClick = () => {
        setSelectedCountry("Portugal");
        setStartDate(new Date());
        setEndDate(new Date());
        setPetType("");
        setEndAfterDate(new Date());
        setErrors([]);
    };
  
    return (
      <SearchHeaderContext.Provider value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedDay,
        setSelectedDay,
        isRepeat,
        setIsRepeat,
        isFilterOpen,
        setIsFilterOpen,
        petType,
        setPetType,
        endAfterDate,
        setEndAfterDate,
        countries,
        setCountries,
        cities,
        setCities,
        selectedCountry,
        setSelectedCountry,
        selectedCity,
        setSelectedCity,
        errors,
        setErrors,
        removeError,
        handleResetClick,
        searchData,
        setSearchData,
      }}>
        {children}
      </SearchHeaderContext.Provider>
    );
  };
  
  export const useSearchHeaderContext = () => {
    const context = useContext(SearchHeaderContext);
    if (!context) {
      if (process.env.NODE_ENV === 'test') {
        return require('./contextMock').defaultMockContextValue;
      } else {
        throw new Error('useSearchHeaderContext must be used within a SearchHeaderProvider')
      }
      
    }
    return context;
  };
